import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Modal, Row } from "antd";
import { toast } from "react-toastify";

import { ReactComponent as Close } from "assets/svg/close.svg";

import {
  upateActionItem,
  // @ts-ignore
} from "modules/actions/PlanActions";

// @ts-ignore
import { onlySpaces } from "utils/utility";

import {
  upateNotes,
  createNotes,
  deleteNotes,
  // @ts-ignore
} from "modules/actions/PlanActions";
import { INote } from "../ActionItems/@types";
import styled from "styled-components";

interface IAddNoteProps {
  data: INote | null;
  isVisible: boolean;
  isEdit?: boolean;
  actionItemId: number;
  planId: number;
  onClose: () => void;
  isDeleted?: boolean;
  isAdd?: boolean;
  isName?: string;
}

enum LOADING {
  DELETE = "delete",
  SAVE = "save",
}

const AddNoteModal: FC<IAddNoteProps> = ({
  data,
  isVisible,
  isEdit,
  planId,
  actionItemId,
  onClose,
  isAdd,
  isName,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<LOADING | false>(false);
  const [value, setValue] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [itemName, setItemName] = useState(isName || "");

  useEffect(() => {
    if (!data || !isVisible) return;

    if (!isAdd && isName) {
      setItemName(isName);
    }

    setValue(data.context);
    setInitialValue(data.context); // Set the initial value when the modal is opened
  }, [data?.context, isVisible, isName]);

  const handleChange = (_: string, value: string) => {
    setValue(value);
  };

  const closeAll = () => {
    setValue("");
    onClose();
  };

  const handleUpdateNote = () => {
    // if (onlySpaces(value)) {
    //   toast("Please enter notes", { type: "error" });
    //   return;
    // }

    if (!isAdd && onlySpaces(itemName)) {
      toast("Please enter action item name", { type: "error" });
      return;
    }

    if (value !== initialValue && data?.id) {
      setLoading(LOADING.SAVE);
      dispatch(
        upateNotes(data?.id, { context: value }, planId, handleCallback)
      );
    } else if (value !== initialValue) {
      const payload = {
        context: value,
        action_item: data?.action_item || actionItemId,
      };
      dispatch(createNotes(payload, planId, handleCallback));
    }

    if (!isAdd && isName !== itemName) {
      dispatch(upateActionItem(actionItemId, { name: itemName }, planId, true));
      handleCallback();
    }
  };

  const handleAddNote = () => {
    if (onlySpaces(value)) {
      toast("Please enter notes", { type: "error" });
      return;
    }

    if (!isAdd && onlySpaces(itemName)) {
      toast("Please enter action item name", { type: "error" });
      return;
    }

    const payload = {
      context: value,
      action_item: data?.action_item || actionItemId,
    };

    if (value !== initialValue) {
      setLoading(LOADING.SAVE);
      dispatch(createNotes(payload, planId, handleCallback));
    }

    if (!isAdd && isName !== itemName) {
      dispatch(upateActionItem(actionItemId, { name: itemName }, planId, true));
      handleCallback();
    }
  };

  const handleDeleteNote = () => {
    setLoading(LOADING.DELETE);
    dispatch(deleteNotes(data?.id, planId, handleCallback));
  };

  const handleCallback = () => {
    setLoading(false);
    setValue("");
    onClose();
  };

  const handleActionItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;

    if (input.length > 255) {
      input = input.slice(0, 255);
    }

    setItemName(input);
  };

  return (
    <Modal
      footer={null}
      closable={false}
      onCancel={closeAll}
      title={false}
      open={isVisible}
    >
      <Row className="space-between mb-4">
        <div className="font-16 text_black text_bold">
          {!isEdit ? "Add Note" : "Update Note"}
        </div>

        <Close
          style={{
            cursor: "pointer",
          }}
          onClick={closeAll}
        />
      </Row>

      {!isAdd && (
        <>
          <NoteHeading>Action Item Name</NoteHeading>
          <AppInput
            type="text"
            className="input"
            placeholder="Action Item Name"
            value={itemName}
            onChange={handleActionItemChange}
          />
        </>
      )}

      <NoteHeading>
        Note{" "}
        <span
          style={{
            color: "#A3A3A3",
          }}
        >
          (Optional)
        </span>
      </NoteHeading>

      <TexArea
        name="context"
        placeholder="Enter your notes here..."
        value={value}
        onChange={(e) => handleChange("context", e.target.value)}
      ></TexArea>

      <Divider className="width110 mt-4" />
      <Row justify="end" className="mt-3">
        {isEdit ? (
          <>
            <Button
              className="createPlanBtn redButton mr-1"
              type="primary"
              loading={loading === LOADING.DELETE}
              disabled={!!loading || !(data?.context?.length)}
              onClick={handleDeleteNote}
            >
              Delete
            </Button>

            <Button
              className="createPlanBtn"
              type="primary"
              // disabled={value === initialValue || !!loading}
              disabled={
                !(value !== initialValue || isName !== itemName) || !!loading
              }
              loading={loading === LOADING.SAVE}
              onClick={handleUpdateNote}
            >
              Save
            </Button>
          </>
        ) : (
          <Button
            className="createPlanBtn"
            type="primary"
            disabled={!value || !!loading}
            loading={loading === LOADING.SAVE}
            onClick={handleAddNote}
          >
            Save
          </Button>
        )}
      </Row>
    </Modal>
  );
};

export default AddNoteModal;

const TexArea = styled.textarea`
  border: 1px solid #e3e5e9;
  border-radius: 4px;
  padding: 12px 8px;
  height: 130px;
  width: 100%;
  resize: none;
  font-size: 14px;

  ::placeholder {
    color: #e6e7eb !important;
  }
`;

const NoteHeading = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #1b2a3d;
  margin-bottom: 10px;
`;

const AppInput = styled.input`
  border: 1px solid #e3e5e9;
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
  ::placeholder {
    color: #e6e7eb !important;
  }
`;
