import { FC } from "react";
import { Dropdown, Menu, MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { unitsData, UnitDataItem } from "./data";
import { getCustomUnitName, PlanDetails, CustomLabel, CustomUnitOption } from './utils';

interface IProps {
  planDetails: PlanDetails;
  disabled: boolean;
  handleChange: (key: string, value: any) => void;
  handleUpdateGoal: (key: string, value: any, type: string) => void;
}

interface IUnit {
  id: number;
  label: string;
}

interface IMergedUnit {
  label: string;
  value: string;
  items: (string | IUnit)[];
}

function mergeArrays(defaultArray: UnitDataItem[], newArray: CustomLabel[]): IMergedUnit[] {
  const mergedArray: IMergedUnit[] = defaultArray.map(unit => ({ ...unit, items: [...unit.items] }));

  newArray.forEach((newItem: CustomLabel) => {
    const unitIndex = mergedArray.findIndex(
      (unit: IMergedUnit) => unit.value.toLowerCase() === newItem.value.toLowerCase()
    );

    if (unitIndex !== -1) {
      const newItems: (string | IUnit)[] = newItem.custom_unit_options.map((option: CustomUnitOption) => ({
        id: option.id,
        label: option.value
      }));

      mergedArray[unitIndex].items = [...new Set([...mergedArray[unitIndex].items, ...newItems])];
    } else {
      mergedArray.push({
        label: newItem.value,
        value: newItem.value,
        items: newItem.custom_unit_options.map((option: CustomUnitOption) => ({
          id: option.id,
          label: option.value
        }))
      });
    }
  });

  return mergedArray;
}

const GoalDropdown: FC<IProps> = ({ planDetails, disabled, handleChange, handleUpdateGoal }) => {
  const dataList = mergeArrays(unitsData, planDetails.goal?.custom_labels);

  const menuItems: MenuProps['items'] = dataList.flatMap(({ label, value, items }, i, groupsList) => {
    const group = {
      key: `${i}`,
      className: "goalWeightOption text_white upercase",
      label,
      popupClassName: "goalPopupOption",
      children: items.flatMap((unit, j, unitsList) => {
        const object = {
          key: `${i}-${j}`,
          className: "goalOption",
          onClick: () => typeof unit === 'object' ? handleUpdateGoal('custom_unit', unit.id, 'custom') : handleUpdateGoal(`${value}_unit`, unit, value),
          label: (
            <span
              className="text_white upercase"
              onClick={() =>
                typeof unit === 'object' ? handleUpdateGoal('custom_unit', unit.id, 'custom') : handleUpdateGoal(`${value}_unit`, unit, value)
              }
            >
              {typeof unit === 'object' ? unit.label : unit}
            </span>
          )
        };

        return j === unitsList.length - 1
          ? [object]
          : [object, { type: "divider" }];
      })
    }

    return i === groupsList.length - 1
      ? [group, { type: "divider" }, {
        key: `custom`,
        onClick: () => handleChange("openCustom", true),
        className: "text_white upercase",
        label: "Custom"
      }]
      : [group, { type: "divider" }];
  });

  const menuProps: MenuProps = {
    items: menuItems,
    className: "goalOption",
  };

  return (
    <Dropdown
      menu={menuProps}
      disabled={disabled}
    >
      <div style={{ marginTop: 4 }} className="mr-2 goalDropdown">
        <h6>
          {getCustomUnitName(planDetails) || "Count"} 
        </h6>
        <DownOutlined className="ml-1 downIcon" />
      </div>
    </Dropdown>
  );
};

export default GoalDropdown;