import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./styles.module.scss";

// Icons
import { ReactComponent as BackIcon } from "assets/svg/BackPrimary.svg";
import { ReactComponent as ShareGray } from "assets/svg/shareGray.svg";
import { ReactComponent as DownloadIcon } from "assets/svg/download.svg";
import { ReactComponent as DotsIcon } from "assets/svg/dots.svg";
import { ReactComponent as MyTask } from "assets/svg/myTask.svg";
import { ReactComponent as CardViewIcon } from "assets/svg/cardView.svg";
import { ReactComponent as ListViewIcon } from "assets/svg/listview.svg";
import { ReactComponent as SearchIcon } from "assets/svg/Search.svg";
import { DownOutlined } from "@ant-design/icons";
import { ReactComponent as GoalIcon } from "assets/svg/goal.svg";
import { ReactComponent as ConnectIcon } from "assets/svg/connect.svg";

// Redux
import { useSelector } from "react-redux";

// Antd
import { Dropdown } from "antd";

// Moment
import moment from "moment";

// Components
import RoundedProgress from "../PlanHeader/components/RoundProgress";

// React Number Format
import { NumericFormat } from "react-number-format";

// Lodash
import debounce from "lodash.debounce";

// Components
import GoalDropdown from "../../ProjectPlanner/components/GoalSettings/GoalDropdown";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";

const PlanNewHeader = ({
  isShared,
  handleUpdate,
  handleChange,
  handleArchiveModal,
  handleRemoveModal,
  handleUpdateGoal,
}) => {
  const { pathname } = useLocation();

  const $objectiveRef = useRef(null);
  const $purposeRef = useRef(null);
  const $saveRef = useRef(null);
  const $buttonsRef = useRef([]);

  const [search, setSearch] = useState("");
  const [isListView, setIsListView] = useState(false);

  const [activeTextTab, setActiveTextTab] = useState(1);
  const [textEdit, setTextEdit] = useState(false);

  const [start, setStartCopy] = useState("");
  const [current, setCurrentCopy] = useState("");
  const [target, setTargetCopy] = useState("");
  const [placeholderStart, setPlaceholderStart] = useState("0.00");
  const [placeholderCurrent, setPlaceholderCurrent] = useState("0.00");
  const [placeholderTarget, setPlaceholderTarget] = useState("0.00");

  const [objectiveCopy, setObjectiveCopy] = useState("");
  const [purposeCopy, setPurposeCopy] = useState("");

  const { planDetails } = useSelector((state) => state.plan.plan);
  const title = planDetails?.name;

  useEffect(() => {
    setObjectiveCopy(planDetails?.objective);
    setPurposeCopy(planDetails?.purpose);
  }, [planDetails?.objective, planDetails?.purpose]);

  const debouncedHandleUpdateGoal = useCallback(
    debounce((field, value) => {
      handleUpdateGoal(field, value);
    }, 500),
    []
  );

  useEffect(() => {
    setStartCopy(planDetails?.goal?.start?.toString() ?? "");
    setCurrentCopy(planDetails?.goal?.current?.toString() ?? "");
    setTargetCopy(planDetails?.goal?.target?.toString() ?? "");
  }, [
    planDetails?.goal?.start,
    planDetails?.goal?.current,
    planDetails?.goal?.target,
  ]);

  const onChangeHandle = (field, value) => {
    if (field === "start") setStartCopy(value);
    else if (field === "current") setCurrentCopy(value);
    else if (field === "target") setTargetCopy(value);

    if (value && !isNaN(value)) {
      debouncedHandleUpdateGoal(field, value);
    } else {
      debouncedHandleUpdateGoal(field, null);
    }
  };

  const disabledGoalInput = isShared || planDetails?.goal?.label === null;

  const onObjectiveBlurHandle = (evt) => {
    if(isShared) return;

    handleUpdate("objective", objectiveCopy);

    if (evt.relatedTarget !== $saveRef.current) {
      setTimeout(() => {
        setTextEdit(false);
      }, 100);
    }
  };

  const onPurposeBlurHandle = (evt) => {
    if(isShared) return;
    handleUpdate("purpose", purposeCopy);

    if (evt.relatedTarget !== $saveRef.current) {
      setTimeout(() => {
        setTextEdit(false);
      }, 100);
    }
  };

  const menuItems = [
    {
      key: "1",
      onClick: () => handleChange("renamePlanModal", true),
      label: <span style={{ color: "#1B2A3D" }}>Rename</span>,
    },
    {
      key: "2",
      onClick: handleArchiveModal,
      label: <span style={{ color: "#1B2A3D" }}>Archive Plan</span>,
    },
    {
      key: "3",
      onClick: handleRemoveModal,
      label: <span style={{ color: "#f00" }}>Delete Plan</span>,
    },
  ];

  const menuProps = {
    className: "removeOption",
    items: menuItems,
  };

  const handleCopyChange = (event, setValue, maxLength = 256) => {
    let input = event.target.value;

    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setValue(input);
  };

  const navTabs = [
    {
      id: "1",
      visible: true,
      text: "Action Items",
      to: `/project-planner/plan-details/${planDetails?.id}`,
    },
    {
      id: "2",
      visible: !isShared,
      text: "Project Plan Details",
      to: `/project-planner/plan-details/${planDetails?.id}/details`,
    },
    {
      id: "3",
      visible: !isShared,
      text: "Resources",
      to: `/project-planner/plan-details/${planDetails?.id}/resources`,
    },
    // {
    //   id: "4",
    //   visible: !isShared,
    //   text: "Project Insights",
    //   to: `/project-planner/plan-details/${planDetails?.id}/resources`,
    // },
  ];

  // Handle click outside
  const handleClickOutside = (event) => {
    if (
      !$objectiveRef.current?.contains(event.target) &&
      !$purposeRef.current?.contains(event.target) &&
      !$buttonsRef.current?.some((ref) => ref.contains(event.target))
    ) {
      setTextEdit(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  };

  return (
    <div className={styles.planHeader}>
      <div className={styles.planHeader_title}>
        <div className={styles.planHeader_title_text}>
          {!isShared && (
            <Link className={styles.back} to="/project-planner/">
              <BackIcon
                style={{
                  cursor: "pointer",
                  marginTop: "-2px",
                }}
              />
            </Link>
          )}

          <h3>PROJECT</h3>
        </div>
        <div className={styles.planHeader_items}>
          {/* <div className={styles.action}>
            <DownloadIcon />
            EXPORT
          </div> */}

          {/* <Link
            className={styles.action}
            to={`/project-planner/plan-details/${planDetails?.id}/resources`}
          >
            <ShareGray />
            SHARE
          </Link> */}
        </div>
      </div>

      <div className={styles.planHeader_details}>
        <div className={styles.planHeader_details_left}>
          <div className={styles.title}>
            <h2 className="text-oneline">{title}</h2>

            {!isShared && (
              <Dropdown menu={menuProps} trigger={["click"]}>
                <button type="button" className={styles.settings}>
                  <DotsIcon />
                </button>
              </Dropdown>
            )}
          </div>

          <h6 className={styles.deadline}>
            Deadline:
            <span>
              <span>{moment(planDetails?.dead_line).format("MM/DD/YYYY")}</span>
            </span>
          </h6>

          <div className={styles.objectiveButtons}>
            <button
              ref={(el) => ($buttonsRef.current[0] = el)}
              className={activeTextTab === 1 ? styles.active : ""}
              type="button"
              onClick={() => {
                setActiveTextTab(1);
                if (!isShared) {
                  setTextEdit(true);
                }
              }}
            >
              Objective
            </button>
            <button
              ref={(el) => ($buttonsRef.current[1] = el)}
              className={activeTextTab === 2 ? styles.active : ""}
              type="button"
              onClick={() => {
                setActiveTextTab(2);
                if (!isShared) {
                  setTextEdit(true);
                }
              }}
            >
              Purpose
            </button>
          </div>

          <div className={styles.objectiveTextarea}>
            {activeTextTab === 1 && (
              <textarea
                ref={$objectiveRef}
                value={objectiveCopy || ""}
                onChange={(event) =>
                  handleCopyChange(event, setObjectiveCopy, 255)
                }
                onBlur={onObjectiveBlurHandle}
                name={"name"}
                readOnly={!textEdit}
              />
            )}

            {activeTextTab === 2 && (
              <textarea
                ref={$purposeRef}
                value={purposeCopy || ""}
                onChange={(event) =>
                  handleCopyChange(event, setPurposeCopy, 255)
                }
                onBlur={onPurposeBlurHandle}
                name={"purpose"}
                readOnly={!textEdit}
              />
            )}
          </div>
        </div>
        <div className={styles.planHeader_details_right}>
          <div className={styles.goalitem}>
            <div className={styles.goalitem_content}>
              {planDetails?.goal?.name ? (
                <h3 className="text-oneline">{planDetails?.goal?.name}</h3>
              ) : (
                <h2>No Goal Connected</h2>
              )}

              {planDetails?.goal?.name ? (
                <h6>
                  <GoalIcon />
                  See Goal
                </h6>
              ) : (
                <h6>
                  <ConnectIcon />
                  Connect Goal
                </h6>
              )}
            </div>
            <div className={styles.goalitem_metric}>
              <div className={styles.goalitem_select}>
                <label>Metric</label>

                <CustomDropdown
                  handleUpdateGoal={handleUpdateGoal}
                  planDetails={planDetails}
                  disabled={isShared}
                  handleChange={handleChange}
                />
              </div>

              <div className={styles.goalitem_input}>
                <label>Start</label>
                <NumericFormat
                  name="start"
                  value={start === "0.00" ? "" : start}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={disabledGoalInput}
                  onChange={(evt) => onChangeHandle("start", evt.target.value)}
                  onFocus={() => setPlaceholderStart("")}
                  onBlur={() => setPlaceholderStart("0.00")}
                  placeholder={placeholderStart}
                  className={styles.goalitem_metric_input}
                />
              </div>
              <div className={styles.goalitem_input}>
                <label>Current</label>
                <NumericFormat
                  name="current"
                  value={current === "0.00" ? "" : current}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={disabledGoalInput}
                  onChange={(evt) =>
                    onChangeHandle("current", evt.target.value)
                  }
                  onFocus={() => setPlaceholderCurrent("")}
                  onBlur={() => setPlaceholderCurrent("0.00")}
                  placeholder={placeholderCurrent}
                  className={styles.goalitem_metric_input}
                />
              </div>
              <div className={styles.goalitem_input}>
                <label>Target</label>
                <NumericFormat
                  name="target"
                  value={target === "0.00" ? "" : target}
                  decimalScale={2}
                  fixedDecimalScale
                  allowemptyformatting="true"
                  disabled={disabledGoalInput}
                  onChange={(evt) => onChangeHandle("target", evt.target.value)}
                  onFocus={() => setPlaceholderTarget("")}
                  onBlur={() => setPlaceholderTarget("0.00")}
                  placeholder={placeholderTarget}
                  className={styles.goalitem_metric_input}
                />
              </div>
            </div>
          </div>

          <div className={styles.progress}>
            <RoundedProgress
              size={100}
              progress={(
                Number(planDetails?.goal?.progress || 0) * 100
              ).toFixed(0)}
              label={"Goal Progress"}
              strokeWidth={7}
              innerStrokeWidth={7}
              circleTwoStroke="#FF9900"
              circleOneStroke="#E5E5E5"
              fill="transparent"
            />

            <RoundedProgress
              size={100}
              progress={(Number(planDetails?.plan_progress || 0) * 100).toFixed(
                0
              )}
              label={"Plan Progress"}
              strokeWidth={7}
              innerStrokeWidth={7}
              circleTwoStroke="#89C740"
              circleOneStroke="#E5E5E5"
              fill="transparent"
            />
          </div>
        </div>
      </div>

      <div className={styles.planHeader_actions}>
        <div className={styles.navWrapper}>
          {navTabs
            .filter((tab) => tab.visible)
            .map((tab) => (
              <Link
                key={tab.id}
                to={tab.to}
                className={`${isShared ? styles.disabled : ""} ${
                  pathname.replace(/\/$/, "") === tab.to.replace(/\/$/, "") ||
                  isShared
                    ? styles.active
                    : ""
                }`}
              >
                {tab.text}
              </Link>
            ))}
        </div>

        <div className={styles.planHeader_options}>
          {/* <div className={styles.button}>
            <MyTask />
            My Tasks
          </div>

          <Dropdown
            overlayClassName="listAndCardView_dropdown"
            menu={{
              items: [
                {
                  label: "List View",
                  icon: <ListViewIcon />,
                  onClick: () => setIsListView(true),
                },
                {
                  label: "Card View",
                  icon: <CardViewIcon />,
                  onClick: () => setIsListView(false),
                },
              ],
            }}
            trigger={["click"]}
            showArrow={true}
          >
            <span className={styles.view}>
              {isListView ? <ListViewIcon /> : <CardViewIcon />}{" "}
              {isListView ? "List View" : "Card View"}
              <DownOutlined />
            </span>
          </Dropdown> */}

          {/* <div className={styles.searchWrapper}>
            <SearchIcon className={styles.icon} width={20} />

            <input
              type="text"
              className={styles.search}
              placeholder={"Search projects"}
              value={search}
              onChange={handleSearch}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PlanNewHeader;
