import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import DeleteNoteModal from "../Modal/DeleteNoteModal";
import styles from "./styles.module.scss";

import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as DeleteIcon } from "./delele.svg";
import { useDispatch, useSelector } from "react-redux";
import { createNotes } from "../../../modules/actions/PlanActions";
import { toast } from "react-toastify";

export default function ProjectPlanDetails({
  planDetails,
  handleUpdatePlan,
  handleChangeMain,
  deleteNoteModal,
  handleRemoveNoteModal,
  handleRemoveNotePress,
  loadingNoteDelete,
  handleUpdateGoal,
  handleEditNoteModal,
}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const [state, setState] = useState({
    purpose: planDetails?.purpose || "",
    name: planDetails?.name || "",
    duration: planDetails?.duration || "",
    description: planDetails?.description || "",
    start_line: moment(planDetails?.start_line) || moment(),
    dead_line: moment(planDetails?.dead_line) || moment(),
    objective: planDetails?.objective || "",
    goalName: planDetails?.goal?.name || "",
    idea: planDetails?.idea || "",
    note: planDetails?.note || "N/A",
  });

  const { loading, loadingNote } = useSelector((state) => state.plan.plan);

  const [endDate, setEndDate] = useState();
  const {
    purpose,
    name,
    description,
    objective,
    duration,
    goalName,
    start_line,
    dead_line,
    idea,
  } = state;

  useEffect(() => {
    setEndDate(dead_line);
  }, []);

  const momentDeadLine = moment(dead_line, "YYYY-MM-DD").format("YYYY-MM-DD");
  const momentStartLine = moment(start_line, "YYYY-MM-DD").format("YYYY-MM-DD");

  useEffect(() => {
    if (planDetails) {
      handleChangeState("name", planDetails?.name);
      handleChangeState("objective", planDetails?.objective);
    }
  }, [planDetails]);

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
    if (key === "start_line" || key === "dead_line") {
      handleUpdate(key, moment(value).format("YYYY-MM-DD"));
    } else {
      handleUpdate(key, value);
    }
  };

  const handleChangeState = (key, value) => {
    let input = value;

    if (input?.startsWith(" ")) {
      input = input.trimStart();
    }
    setState((pre) => ({ ...pre, [key]: input }));
  };

  const handleUpdate = (key, value) => {
    const payload = {
      [key]: value,
    };
    // handleUpdatePlan(payload);
  };

  const handleSave = () => {
    if (!name || !start_line || !dead_line) {
      toast.error("Please fill all required fields");
      return;
    }

    if (momentDeadLine && momentDeadLine < momentStartLine) {
      toast.error("End date should be greater than start date");
      return;
    }

    const formateData = {
      start_line: moment(start_line).format("YYYY-MM-DD"),
      dead_line: moment(dead_line).format("YYYY-MM-DD"),
      name,
      objective: objective || "",
      purpose,
      idea,
      description,
      goalName: goalName || "null",
    };

    handleUpdatePlan(formateData);
  };

  const disableSaveButton =
    name === planDetails?.name &&
    objective === planDetails?.objective &&
    moment(start_line)?.format("YYYY-MM-DD") === planDetails?.start_line &&
    moment(dead_line)?.format("YYYY-MM-DD") === planDetails?.dead_line &&
    goalName === String(planDetails?.goal?.name || "") &&
    idea === planDetails?.idea &&
    purpose === planDetails?.purpose &&
    description === planDetails?.description;

  const handleAddNote = () => {
    if (!value) {
      toast.error("Note is required");
      return;
    }
    const payload = {
      context: value,
      project_plan: planDetails?.id,
    };

    const closeAll = () => {
      setValue("");
    };

    dispatch(createNotes(payload, planDetails?.id, closeAll));
  };

  const handleCancel = () => {
    setState({
      purpose: planDetails?.purpose || "",
      name: planDetails?.name || "",
      duration: planDetails?.duration || "",
      description: planDetails?.description || "",
      start_line: moment(planDetails?.start_line) || moment(),
      dead_line: moment(planDetails?.dead_line) || moment(),
      objective: planDetails?.objective || "",
      goalName: planDetails?.goal?.name || "",
      idea: planDetails?.idea || "",
      note: planDetails?.note || "N/A",
    });
  };

  return (
    <>
      <div className={styles.projectPlanDetails}>
        <div className={styles.projectPlanDetails_form}>
          <div className={styles.projectPlanDetails_header}>
            <h2>Project Plan Details</h2>

            <div className={styles.projectPlanDetails_form_actions}>
              <button
                disabled={loading || disableSaveButton}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                loading={loading}
                disabled={loading || disableSaveButton}
              >
                {loading ? <LoadingOutlined /> : "Save"}
              </button>
            </div>
          </div>

          <div className={styles.form}>
            <div className={styles.form_left}>
              <div className={styles.inputGroup}>
                <label className={styles.label}>Project Name</label>
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    value={name}
                    name={"name"}
                    placeholder="Text"
                    onChange={(value) =>
                      handleChangeState(value.target.name, value.target.value)
                    }
                    onBlur={(key, value) =>
                      name
                        ? handleUpdate("name", name)
                        : console.log("key,value")
                    }
                  />
                  {!name && (
                    <label className={styles.error}>
                      Project Name is required
                    </label>
                  )}
                </div>
              </div>

              <div className={styles.inputGroup}>
                <label className={styles.label}>Goal (Optional)</label>

                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    value={goalName}
                    name={"goalName"}
                    placeholder="Text"
                    onChange={(event) => {
                      let input = event.target.value;

                      if (input.length > 255) {
                        input = input.slice(0, 255);
                      }

                      handleChangeState(event.target.name, input);
                    }}
                  />
                </div>
              </div>

              {/* 
              <div className={styles.inputGroup}>
                <label className={styles.label}>Goal (Optional)</label>
                <div className={`${styles.selectWrapper} projectDetail-select`}>
                  <Select
                    options={[
                      {
                        value: "develop_leadership",
                        label: "Develop Organisational Leadership",
                      },
                      {
                        value: "improve_productivity",
                        label: "Improve Team Productivity",
                      },
                      {
                        value: "enhance_communication",
                        label: "Enhance Communication Skills",
                      },
                      {
                        value: "improve_customer_satisfaction",
                        label: "Improve Customer Satisfaction",
                      },
                      {
                        value: "enhance_innovation",
                        label: "Enhance Innovation and Creativity",
                      },
                    ]}
                  />
                </div>
              </div> */}

              <div className={styles.inputGroup}>
                <label className={styles.label}>Objective</label>
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    value={objective || ""}
                    name={"objective"}
                    onChange={(event) => {
                      let input = event.target.value;

                      if (input.length > 255) {
                        input = input.slice(0, 255);
                      }

                      handleChangeState(event.target.name, input);
                    }}
                    onBlur={() => handleUpdate("objective", purpose)}
                    placeholder="Enter your objective"
                  />
                </div>
              </div>

              <div className={styles.inputGroup}>
                <label className={styles.label}>Purpose</label>
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    value={purpose || ""}
                    name={"purpose"}
                    onChange={(event) => {
                      let input = event.target.value;

                      if (input.length > 255) {
                        input = input.slice(0, 255);
                      }

                      handleChangeState(event.target.name, input);
                    }}
                    onBlur={() => handleUpdate("purpose", purpose)}
                    placeholder="Enter your purpose"
                  />
                </div>
              </div>

              <div className={styles.inputGroup}>
                <label className={styles.label}>Brainstorm Ideas</label>

                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    value={idea || ""}
                    name={"idea"}
                    onChange={(value) =>
                      handleChangeState(value.target.name, value.target.value)
                    }
                    onBlur={() => handleUpdate("idea", idea)}
                    placeholder="Enter your ideas"
                  />
                </div>
              </div>
            </div>

            <div className={styles.form_right}>
              <div className={styles.inputGroup}>
                <label className={styles.label}>Start Date</label>

                <DatePicker
                  value={start_line}
                  name={"start_line"}
                  format={"MM/DD/YYYY"}
                  clearIcon={false}
                  style={{ width: "100%" }}
                  onChange={(date) => handleChange("start_line", date)}
                  placeholder="MM.DD.YYYY"
                  className="projectDetail-datepicker"
                />
              </div>

              <div className={styles.inputGroup}>
                <label className={styles.label}>End Date</label>
                <div className={styles.datePickerWrapper}>
                  <DatePicker
                    clearIcon={false}
                    format={"MM/DD/YYYY"}
                    value={dead_line ? dead_line : endDate}
                    disabledDate={(current) => {
                      return (
                        current && current < moment(start_line, "MM/DD/YYYY")
                      );
                    }}
                    style={{ width: "100%" }}
                    name={"dead_line"}
                    onChange={(date) => handleChange("dead_line", date)}
                    placeholder="MM/DD/YYYY "
                    className="projectDetail-datepicker"
                  />

                  {momentDeadLine && momentDeadLine < momentStartLine && (
                    <label className={styles.error}>
                      End date should be greater than start date
                    </label>
                  )}
                </div>
              </div>

              <div className={styles.duration}>
                <label className={styles.label}>Duration</label>

                <h6>
                  {moment(dead_line).diff(moment(start_line), "days") > 0
                    ? moment(dead_line).diff(moment(start_line), "days") +
                      " days"
                    : "--"}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.projectPlanDetails_notes}>
          <h2> Notes</h2>
          {planDetails?.goal?.notes?.length > 0 ? (
            <div className={styles.notesItems}>
              {planDetails?.goal?.notes?.map((note, index) => (
                <div className={styles.notesItem} key={index}>
                  <div>
                    <h5>{note?.context}</h5>

                    <span>{moment(note?.updated_at).fromNow()}</span>
                  </div>
                  <div className={styles.notes_actions}>
                    <EditIcon
                      onClick={() =>
                        handleEditNoteModal(note?.id, note?.context)
                      }
                    />
                    <DeleteIcon
                      onClick={() => handleRemoveNoteModal(note?.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.noItems}>
              <h5>No notes available</h5>
            </div>
          )}

          <div className={styles.addNote}>
            <input
              placeholder="Add note"
              onChange={(e) => {
                let value = e.target.value;

                if (value.startsWith(" ")) {
                  value = value.trimStart();
                }

                setValue(value);
              }}
              value={value}
            />

            <button
              onClick={handleAddNote}
              loading={loadingNote}
              disabled={loadingNote || !value}
            >
              {loadingNote ? <LoadingOutlined /> : "Add"}
            </button>
          </div>
        </div>
      </div>

      <DeleteNoteModal
        visible={deleteNoteModal}
        heading={"Delete Note"}
        onClose={() => handleChangeMain("deleteNoteModal", false)}
        descriptions={"Are you sure you want to delete the note?"}
        handleRemove={handleRemoveNotePress}
        loading={loadingNoteDelete}
      />
    </>
  );
}
